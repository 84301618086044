const zh = {
  link: '连接钱包',
  pledge:"质押",
  InterestReceivable:"可领取利息",
  ReceiveInterest:"领取利息",
  WithdrawablePrincipal:"可提取本金",
  WithdrawalOfPrincipal:"提取本金",
  balance:"余额",
  AmountInLock:"锁定的金额"
}

export default zh;